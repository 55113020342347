import http from '@/services/api';
import { mountUrlWP } from '@/utils/mountURL'
import content from '@/services/content';

export function sendGetCarouselList(): Promise<any> {
  const url = mountUrlWP('/get_posts/?destaque=slide&count=4');
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function sendGetAllNews(query = ''): Promise<any> {
  let url = mountUrlWP('/get_posts/?slug=noticia&orderby=date&order=desc&post_type=post');
  url = (query != '') ? url +'&'+ query : url;
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function getThumbnail(post: any) {
  return content.getPostUrlImage(post);
}

export default {
  sendGetCarouselList,
  sendGetAllNews,
  getThumbnail
}