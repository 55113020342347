import { Component, Prop, Vue } from 'vue-property-decorator'

import { sendGetCarouselList } from '@/modules/news/services/news';
import { getPostUrlImage } from '@/services/content'
import Utils from '@/utils/index'

@Component
export default class ListRecentNews extends Vue {
  listItens = []
  isLoading = false;

  getPostUrlImage = getPostUrlImage;

  mounted() {
    this.getNewsCarousel();
  }
  
  getNewsCarousel(): void{
    this.isLoading = true;
    sendGetCarouselList()
      .then( (response) => {
        this.listItens = response.data.posts
      })
      .finally( ()=>this.isLoading = false );
  }

  clickPost(post: any){
    this.$emit('clickNews', post)
  }

  convertDataPtBr(strData: string){
    return Utils.date.strDatetimeUSAToBR(strData);
  }

}
