import { Component, Vue } from 'vue-property-decorator'

import { sendGetCarouselList } from '@/modules/news/services/news';

import { getPostUrlImage } from '@/services/content'


@Component({
  components: {}
})
export default class CarouselNews1 extends Vue {
  list = []
  isLoading = false;
  getPostUrlImage = getPostUrlImage;

  mounted() {
    this.getNewsCarousel();
  }
  
  getNewsCarousel(): void{
    this.isLoading = true;
    sendGetCarouselList()
      .then( (response) => {
        this.list = response.data.posts
      })
      .finally( ()=>this.isLoading = false );
  }

  clickCarousel(post: any){
    if( post.type == 'boletim' ){
      this.$router.push(`/boletim/${post.slug}`);
    }
    else{
      this.$router.push(`/noticia/${post.slug}`);
    }
  }

}
  